import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-membership',
	templateUrl: './membership.component.html',
	styleUrls: ['./membership.component.scss'],
})
export class MembershipComponent {
	public accordion = [false, false, false, false, false, false, false, false];

	public membershipFeatures = [
		{ name: 'Central Book List with Promo Reminders', level: 'free' },
		{ name: 'Saved Promo History with Easy Recheduling', level: 'free' },
		{ name: 'Author Community on Facebook', level: 'free' },
		{ name: 'Email Support', level: 'free' },
		{ name: '10% discount on all Written Word Media services', level: 'gold' },
		{ name: 'Early Access to Inventory (14 days)', level: 'gold' },
		{ name: 'Discounts on 3rd part partner products and services', level: 'gold' },
		{ name: 'Access to Limelight (dedicated email where you are the only featured author)', level: 'gold' },
		{ name: 'Access to Speaker Series Recordings', level: 'gold' },
		{ name: 'Priority Access to Beta Products', level: 'platinum' },
		{ name: 'Priority Editorial Placement (free bonus promos when we need to fill a slot!)', level: 'platinum' },
	];

	constructor(
		private router: Router,
	) {}

	toggleAccordionItem(key) {
		this.accordion[key] = !this.accordion[key];
	}

	navigateToSection(section: string): void {
		const element = document.getElementById(section);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	}
}

<div class="background">
    <div class="container" id="Plans">
        <div class="header flex">
            <div class="card cta">
                <h2>Choose Your Membership Level</h2>
                <p>
                    Become a Gold or Platinum Member to save time, money, and get exclusive access to tools, 
                    resources, and opportunities to grow your author business.<br><br>
                    Not ready to take the plunge? Our Free plan is a great place to start
                </p>
            </div>
        </div>
        <div class="body flex">
            <div class="card">
                <div class="card-header">
                    <h3>Free</h3>
                    <p>I want to get organized and save time</p>
                </div>
                <div class="card-details">
                    <div class="price flex">
                        <div class="currency">$</div>
                        <div class="dollar">0</div>
                    </div>
                    <div class="period">
                        Per Year
                    </div>
                    <div class="discount">
                        (no discounts)
                    </div>
                </div>
                <div class="card-body">
                    <ul>
                        <li *ngFor="let membershipFeature of membershipFeatures">
                            <div [ngClass]="{'included': membershipFeature.level === 'free'}">{{ membershipFeature.name }}</div>
                        </li>
                    </ul>
                </div>
                <div class="card-footer flex">
                    <!-- <button class="btn">Create Free Account</button> -->
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h3>Gold</h3>
                    <p>I'm ready to level up my book marketing</p>
                </div>
                <div class="card-details">
                    <div class="price flex">
                        <div class="currency">$</div>
                        <div class="dollar">10</div>
                        <div class="cents-period">42/month</div>
                    </div>
                    <div class="period">
                        $125 paid annually
                    </div>
                    <div class="discount">
                        (10% off all products up to $200 max)
                    </div>
                </div>
                <div class="card-body">
                    <ul>
                        <li *ngFor="let membershipFeature of membershipFeatures" >
                            <div [ngClass]="{'included': membershipFeature.level !== 'platinum'}">{{ membershipFeature.name }}</div>
                        </li>
                    </ul>
                </div>
                <div class="card-footer flex">
                    <!-- <button class="btn">Select Plan</button> -->
                </div>
                <div class="ribbon">
                    Popular
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h3>Platinum</h3>
                    <p>Get me on the bestseller list!</p>
                </div>
                <div class="card-details">
                    <div class="price flex">
                        <div class="currency">$</div>
                        <div class="dollar">20</div>
                        <div class="cents-period">83/month</div>
                    </div>
                    <div class="period">
                        $250 paid annually
                    </div>
                    <div class="discount">
                        (10% off all products up to $500 max)
                    </div>
                </div>
                <div class="card-body">
                    <ul>
                        <li *ngFor="let membershipFeature of membershipFeatures">
                            <div class="included">{{ membershipFeature.name }}</div>
                        </li>
                    </ul>
                </div>
                <div class="card-footer flex">
                    <!-- <button class="btn">Select Plan</button> -->
                </div>
                <div class="ribbon">
                    Best Value
                </div>
            </div>
        </div>
        <div class="footer">
            <div>Upgrade from free to paid anytime.</div>
            <a href="https://www.writtenwordmedia.com/exploring-the-benefits-of-written-word-media-premium-membership/" class="underlined">Learn More About Membership</a>
        </div>
    </div>

    <div class="secondary-container">
        <div class="featured-testimonial">
            <div class="featured-testimonial-description">
                "I absolutely 100% recommend that other authors become Gold Members--but it's so good and I'm so greedy, 
                I would almost rather keep it a secret! Seriously, though, becoming a Gold Member has helped me out more than I expected. <br>
                And, best yet, Written Word Media keeps adding more and more goodies to the pot, increasing the value of membership. <br>
                Needless to say, I plan to renew my membership every year. It's been a purchase I have not once regretted!"
            </div>
            <div class="featured-testimonial-author">
                <div class="featured-testimonial-author-image">
                    <img decoding="async" 
                        src="https://www.writtenwordmedia.com/wp-content/uploads/2023/04/Jade-small-bio-pic.png" alt="Jade C. Jamison">
                </div>
                <cite class="featured-testimonial-author-name">
                    <span class="featured-testimonial-author-username">Jade C. Jamison</span>
                    <span>International Bestselling Author </span>
                </cite>			
            </div>
        </div>

        <div class="membership-benefits">
            <h2 class="membership-benefits-title">Membership Benefits</h2>
            <div class="row">
                <div class="col-6">
                    <div class="membership-benefit">
                        <div class="membership-benefit-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/elementor/thumbs/Membership-LP-pqan1zqgvwe92elb8jg5rxpur0gwp10ps42b4qr8ve.png" 
                                title="Membership LP" alt="Membership LP">
                        </div>
                        <div class="membership-benefit-title">10 % Discount On All Purchases</div>
                        <div class="membership-benefit-description">
                            <p>Gold and Platinum members receive 10% off any purchase from Written Word Media. 
                                The total annual discount amount is capped at $200 for Gold Members, and $500 for platinum.</p>
                            <p>Members can easily track their discount usage in their account to make sure they maximize their savings.</p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="membership-benefit">
                        <div class="membership-benefit-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/elementor/thumbs/Membership-LP-1-pqan1ysnqcb9oa9xo3iume8vzke3y8fnt12yb3gre2.png" 
                                title="Membership LP (1)" alt="Membership LP (1)">
                        </div>
                        <div class="membership-benefit-title">14-Day Advance Access to Inventory</div>
                        <div class="membership-benefit-description">
                            <p>Gold and Platinum members have access to promos 14 days in advance of free members.</p>
                            <p>This means that as a Gold or Platinum member, you have a better opportunity to get the exact 
                                promos and promo dates you want.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="membership-benefit">
                        <div class="membership-benefit-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/elementor/thumbs/Membership-LP-2-pqan1xuu1xcwxg9ok2xte8i8dnwan8w9gaduhs9gec.png" 
                                title="Membership LP (2)" alt="Membership LP (2)">
                        </div>
                        <div class="membership-benefit-title">Access to Limelight</div>
                        <div class="membership-benefit-description">
                            <p>Limelight is our exclusive dedicated e-blast product that promotes a single author at a time. 
                                This powerful promo is available to Gold and Platinum members and provides authors with a new 
                                level of premium marketing that puts your work in a premium showcase.</p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="membership-benefit">
                        <div class="membership-benefit-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/elementor/thumbs/Membership-LP-3-pqan1xuu1xctnlhsnwr0twdfincf8pdo8j3skif25g.png" 
                                title="Membership LP (3)" alt="Membership LP (3)">
                        </div>
                        <div class="membership-benefit-title">Partner Perks</div>
                        <div class="membership-benefit-description">
                            <p>Gold and Platinum members have exclusive access to discounts and deals from publishing 
                                industry players. Save on the tools you need to take your author business to the next level.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="membership-benefit">
                        <div class="membership-benefit-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/elementor/thumbs/Membership-LP-4-pqan1wwzco8lr7ks2wisx2l5xs3i4aplx0hxf9p5hm.png" 
                                title="Membership LP (4)" alt="Membership LP (4)">
                        </div>
                        <div class="membership-benefit-title">Community</div>
                        <div class="membership-benefit-description">
                            <p>All members are invited to join the Written Word Media Facebook Group. Here you can learn from other authors, 
                                attend Q&As, and ask book marketing questions to the experts at Written Word Media. Gold and Platinum members 
                                have exclusive access to our Speaker Series which gives you access to keynotes presentations and workshops to 
                                help you succeed. </p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="membership-benefit">
                        <div class="membership-benefit-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/elementor/thumbs/Membership-LP-5-pqan20oco6m38uqv3cq11ze1pg9m0jcag6cnh14tiq.png" 
                                title="Membership LP (5)" alt="Membership LP (5)">
                        </div>
                        <div class="membership-benefit-title">Support</div>
                        <div class="membership-benefit-description">
                            <p>Members, as always, have access to our world-class customer service via email. And Gold and Platinum 
                                members have access to live chat in their accounts. Questions about which promo to choose, if you can 
                                change your dates, or anything else? Simply send our team an email, or log into your member account for 
                                answers in seconds.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dots-section">
            <div class="row">
                <div class="col-4">
                    <div class="dot-item">
                        <div class="dot-item-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/2022/06/Membership-LP-6.png" 
                                class="attachment-full size-full" alt="">
                        </div>
                        <div class="dot-item-title">Book Marketing That Works</div>
                        <div class="dot-item-description">
                            <p>We create effective, easy to use book marketing products, and we're proud of our reputation. 
                                Over 30,000 authors have used Written Word Media find their readers.</p>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="dot-item">
                        <div class="dot-item-image">
                            <img decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/2022/06/Membership-LP-7.png" 
                                class="attachment-full size-full" alt="">
                        </div>
                        <div class="dot-item-title">World Class Customer Service</div>
                        <div class="dot-item-description">
                            <p>We take pride in supporting authors, and it shows. When you work with Written Word Media, 
                                we do everything possible to make your experience great.</p>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="dot-item">
                        <div class="dot-item-image">
                            <img loading="lazy" decoding="async" 
                                src="https://www.writtenwordmedia.com/wp-content/uploads/2022/06/Membership-LP-8.png" 
                                class="attachment-full size-full" alt="">
                        </div>
                        <div class="dot-item-title">We Support You</div>
                        <div class="dot-item-description">
                            <p>Whether you're finding your first fans or publishing your 20th book, Written Word Media members 
                                know that we are here to support you in any way we can.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="secondary-container">
    <div class="testimonials">
        <div class="testimonials-title">Testimonials</div>

        <div class="testimonial">
            <div class="testimonial-description">
                <p>“Written Word Media is my go to promotion service. The membership level is more than worth the cost.”</p>
            </div>
            <div class="testimonial-author">
                <div class="testimonial-author-image">
                    <img decoding="async" 
                        src="https://www.writtenwordmedia.com/wp-content/uploads/2024/02/Manning-Wolfe.jpeg" 
                        alt="Manning Wolfe">
                </div>
                <cite class="testimonial-author-name">
                    <span class="testimonial-author-username">Manning Wolfe</span>
                    <span>Award-winning author and attorney</span>
                </cite>
            </div>
        </div>
        <div class="testimonial">
            <div class="testimonial-description">
                <p>“Written Word Media gives me just the edge I need in today’s competitive market. When you need to 
                    propel your sale in front of hungry readers, this is the place to be. I never hesitate booking an 
                    ad with them.”</p>
            </div>
            <div class="testimonial-author">
                <div class="testimonial-author-image">
                    <img decoding="async" 
                        src="https://www.writtenwordmedia.com/wp-content/uploads/2022/07/Addison-Moore.jpg" 
                        alt="Addison Moore">
                </div>
                <cite class="testimonial-author-name">
                    <span class="testimonial-author-username">Addison Moore</span>
                    <span>New York Times bestselling author</span>
                </cite>
            </div>
        </div>
        <div class="testimonial">
            <div class="testimonial-description">
                <p>“Whenever I’m launching a new series or breathing life into an old one, I know I can count on Written Word Media. They rock!”</p>
            </div>
            <div class="testimonial-author">
                <div class="testimonial-author-image">
                    <img decoding="async" 
                        src="https://www.writtenwordmedia.com/wp-content/uploads/2022/07/craig-author-pic-1--e1657199263387.jpeg" 
                        alt="Craig Halloran">
                </div>
                <cite class="testimonial-author-name">
                    <span class="testimonial-author-username">Craig Halloran</span>
                    <span>#1 Bestselling Epic Fantasy Author</span>
                </cite>
            </div>
        </div>
    </div>

    <div class="cta-wrapper">
        <a (click)="navigateToSection('Plans')" class="button-call-to-action" role="button">
            <span class="">Choose Your Plan</span>
        </a>
    </div>

    <div class="faqs">
        <div class="faqs-title">FAQs</div>

        <div class="accordion" id="accordionFAQ">
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading1">
                    <button class="accordion-button collapsed" type="button" (click)="toggleAccordionItem(0)" 
                        [ngClass]="{ 'collapsed': !accordion[0] }">
                        What is the refund policy for my Gold or Platinum membership fee?
                    </button>
                </h2>
                <div id="collapse1" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[0] }">
                    <div class="accordion-body">
                        Members can cancel their Gold or Platinum membership within 14 days of their start day to 
                        receive a full refund provided they have not used any of their discount allocation.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading2">
                    <button class="accordion-button collapsed" type="button" 
                        [ngClass]="{ 'collapsed': !accordion[1] }" (click)="toggleAccordionItem(1)">
                        Can I change my membership to month-to-month?
                    </button>
                </h2>
                <div id="collapse2" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[1] }">
                    <div class="accordion-body">
                        No. Written Word Media memberships run on an annual basis. There is no monthly payment option.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading3">
                    <button class="accordion-button collapsed" type="button" 
                        [ngClass]="{ 'collapsed': !accordion[2] }" (click)="toggleAccordionItem(2)">
                        Do I have to be logged in to receive my discount on email promos?
                    </button>
                </h2>
                <div id="collapse3" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[2] }">
                    <div class="accordion-body">
                        Yes. Members must be logged in to their accounts when purchasing promos in order to receive 
                        their discounts. When a member is logged in, the discount is automatically applied at checkout, 
                        and cannot be retroactively applied.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading4">
                    <button class="accordion-button collapsed" type="button" 
                    [ngClass]="{ 'collapsed': !accordion[3] }" (click)="toggleAccordionItem(3)">
                        What is a “promo”?
                    </button>
                </h2>
                <div id="collapse4" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[3] }">
                    <div class="accordion-body">
                        A promo is our term for a book marketing placement. For example, a promo could refer to a 
                        placement in one of our daily email promos like Bargain Booksy, or it could refer to a Reader Reach Facebook Ads campaign.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading5">
                    <button class="accordion-button collapsed" type="button" 
                    [ngClass]="{ 'collapsed': !accordion[4] }" (click)="toggleAccordionItem(4)">
                        If I am a free member can I still run Written Word Media promos?
                    </button>
                </h2>
                <div id="collapse5" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[4] }">
                    <div class="accordion-body">
                        Yes! Free members have access to all promos except for Limelight.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading6">
                    <button class="accordion-button collapsed" type="button" 
                    [ngClass]="{ 'collapsed': !accordion[5] }" (click)="toggleAccordionItem(5)">
                        What services does the 10% discount for Gold and Platinum apply to?
                    </button>
                </h2>
                <div id="collapse6" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[5] }">
                    <div class="accordion-body">
                        The 10% discount applies to all purchases (except for membership fees) with Written Word Media.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading7">
                    <button class="accordion-button collapsed" type="button" 
                    [ngClass]="{ 'collapsed': !accordion[6] }" (click)="toggleAccordionItem(6)">
                        I have a Written Word Media account. How do I find out what membership level I have?
                    </button>
                </h2>
                <div id="collapse7" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[6] }">
                    <div class="accordion-body">
                        Simply log in to your account. Your membership level will be reflected on your homepage.
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading8">
                    <button class="accordion-button collapsed" type="button" 
                    [ngClass]="{ 'collapsed': !accordion[7] }" (click)="toggleAccordionItem(7)">
                        I previously created a Writtten Word Media account, what membership level am I?
                    </button>
                </h2>
                <div id="collapse8" class="accordion-collapse collapse" [ngClass]="{ 'show': accordion[7] }">
                    <div class="accordion-body">
                        By default, all Written Word Media account holders are free members. No action is required 
                        from you unless you wish to upgrade your membership.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
